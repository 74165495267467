.workspace-table-header__name {
    text-align: left;
    padding-left: 1rem;
}

.workspace-table-header__last_run {
    text-align: right;
    padding-right: 1rem;
    float: right;
}

.workspace-table {
    /*height: 79vh;*/
}

/*.workspace-table_row {*/
/*    border: #1f1d1b 1px solid;*/
/*}*/
.client-table__name {
    text-align: left;
    padding-left: 1rem;
}

.client-table__last_run {
    text-align: right;
    padding-right: 1rem;
}

.client-table_row {
    /*border: #1f1d1b 1px solid;*/
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

* {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width:5px;
  height:5px;
}

::-webkit-scrollbar-thumb {
  border-radius:5px;
  background-color: #ccc;
}

.sidebar-content a {
	text-transform:capitalize;
}

/*.homepage-container {*/
/*    height: 100vh;*/
/*}*/

/*.landing-page {*/
/*  background-image: url('./static/img/chief-logo.png');*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  height: 50vh;*/
/*  !*width: 100% !important;*!*/
/*}*/

.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.landing-page {
  text-align: center;
}

.content {
    margin-top: 10em;
}